import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const HeaderInfoPage = ({ title }) => {
  return (
    <HeaderWrapper>
      <TitleHeader>{title}</TitleHeader>
      <HeaderNav>
        <Link to="/">Home</Link>
        <span>{title}</span>
      </HeaderNav>
    </HeaderWrapper>
  );
};

HeaderInfoPage.propTypes = {
  siteTitle: PropTypes.string
};

HeaderInfoPage.defaultProps = {
  siteTitle: ``
};

const HeaderWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #008e8e;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0 0;
  background-image: url(/header-title-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #009298;
    opacity: 0.6;
  }
`;
const TitleHeader = styled.h2`
  color: #fff;
  margin: 10px 0 65px;
  font-size: 45px;
  text-align: center;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  position: relative;
  z-index: 100;
  text-transform:capitalize;
`;

const HeaderNav = styled.div`
  padding: 12px 10px;
  margin: 0 0 -20px;
  text-align: center;
  display: block;
  vertical-align: top;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 20px -4px rgb(0 0 0 / 27%);
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 220px;
  a {
    font-family: Poppins, sans-serif;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    color: #292929;
    :hover {
      opacity: 0.8;
    }
  }
  span {
    font-family: Poppins, sans-serif;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    color: #009298;
    margin-left: 24px;
    position: relative;
    text-transform:capitalize;
    :before {
      width: 5px;
      height: 5px;
      background: #009298;
      left: -14.5px;
      content: "";
      float: left;
      position: absolute;
      top: 11px;
      border-radius: 50%;
    }
  }
`;

export default HeaderInfoPage;
