import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";


const SubscribeSuccess = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Lead', {content_category: "Sign Up"});
      }
    }
  });

  return (
    <Layout>
      <SEO title="Subscribed Successfully" />
      <HeaderInfoPage title="Thank You!" />
      <Body>
        <Text>
          <h2>
            Thank You for Subscribing!
        </h2>
          <p>
            We are excited to have you on our mailing list!
        </p>
          <p>
            Make sure to follow us on our social media channels to get the latest information about Sajdah.
        </p>
          <SocialIcons>
            <SocialIcon
              href="https://www.facebook.com/GetSajdah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/facebook.svg" alt="Follow Sajdah on Facebook" />
            </SocialIcon>
            <SocialIcon
              href="https://www.linkedin.com/company/thakaatechnologies"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/linkedin.svg" alt="Follow Thakaa Technologies on LinkedIn" />
            </SocialIcon>
            <SocialIcon
              href="https://twitter.com/GetSajdah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/twitter-icon.svg" alt="Follow Sajdah on Twitter" />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/GetSajdah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/instagram-logo.svg" alt="Follow Sajdah on Instagram" />
            </SocialIcon>
            <SocialIcon
              href="https://www.youtube.com/channel/UC83JEfN_l3MqSv5phtTE3jw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/youtube.svg" alt="Follow Sajdah on YouTube" />
            </SocialIcon>
          </SocialIcons>
        </Text>
      </Body>
    </Layout>
  );
};



const Body = styled.div`
  padding: 32px 0;
  background: #f5f7fd;
  width: 100%;
`;

const Text = styled.div`
  box-shadow: 0 15px 38px rgb(0 0 0 / 10%);
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 32px;
  font-family: Poppins, sans-serif;
  h2 {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
    font-size: 32px;
  }
  p {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
    line-height: 26px;
    max-width: 100%;
  }
  li {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
    margin-bottom: 12px;
  }
  a {
    color: #009298;
    text-decoration: none;
    margin-right: 4px;
  }
  @media (max-width: 960px) {
    max-width: 85%;
    padding: 24px;
  }
`;



const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
  margin:24px auto;
  
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  background-color: #008e8e;
  border-radius: 100%;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
  img {
    max-width: 20px;
  }
`;


export default SubscribeSuccess;
